@font-face {
    font-family: ProductSansRegular;
}

@font-face {
    font-family: ProductSansBold;
}

body{
    padding: 0px;
    margin: 0px;
    background-color:"#fff";
    font-family: ProductSansRegular;
}

.how-to{
    padding-left: 25px;
    padding-top: 65px;

}

.how-to-left{
    text-align: center;
}

.how-to-left > h1{
    font-size: 26px;
    color:#3e3e3e;
    font-weight: 800;
    padding-bottom: 20px;
}

.how-to-content{
    font-size: 16px;
    /*font-weight:600;*/
    padding-right: 20px;
    font-family: ProductSansRegular;

}

.how-to-content img{
    width: 70px;
    height: 70px;
}

.how-to-content span{
    font-weight: bold;
    font-size: 1.5em;
    padding-left: 20px;
}

.recicla{
    background-color: #ffffff;
    height: 200px;
}

.siguiente{
    height: 140px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.codigo_orden{
    text-decoration: underline solid #1BE9B6;
    font-size: 24px;
}

.boton-recicla{
    padding-top: 70px;
    height: 100px;
}

.footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

@media screen and (max-width: 1200px){
    .how-to-content span{
        font-size:18px;
    }
}

@media screen and (max-width: 1100px){
    .how-to-content span{
        font-size:17px;
    }
}

@media (min-width: 1200px){
    .container {
        max-width: 1400px;
    }
}

a:link{
    text-decoration: none;


}

.how-to-content p{
    padding-top: 20px;
    padding-left: 100px;
    text-align: justify;
}

.container-fluid{
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
}

.menu{
    background-color: #ffffff;
}

.logo{
    margin-right: 50px;
    margin-left: 50px;
    max-width: 80px !important;
}

.banner{
    position: relative;
}


.foto-materials{
    position: relative;
}

.foto-materials:hover{
    background-color: #3e3e3e;
}

.col{
    padding-left: 0px;
    padding-right: 0px;
}

.texto-banner{
    position: absolute;
    top: 25%;
    left:15%;
    background-color:"#1BE9B6";
    padding: 30px;
    font-size: 2vw;
    transform: translate(-30%, -10%);
}

.texto-banner-short{
    width: 280px;
    position: absolute;
    top: 35%;
    left:15%;
    background-color:"#1BE9B6";
    padding: 10px;
    font-size: 2vw;
    transform: translate(-30%, -10%);
}

.texto-foto{
    position: absolute;
    top: 95%;
    left:5%;
    background-color:"#1BE9B6";
    padding: 10px;
    font-size: 2vw;
    transform: translate(-5%, -85%);
    color:white;
    font-weight: bold;
}

.title {
    border-bottom: 8px solid #57c4d0;
    width: 350px;
    margin-bottom: 40px;
    text-align: left !important;
}

.enlace-texto-banner{
    color: #ffffff !important;
}

.btn-ingresa{
    background-color: blue;
    color:white;
    margin-right: 30px;
    font-size: 14px;
}

.sombra > li:hover{

    background-color:rgb(27,233,182);
    color:white !important;
}

.sombra > li{
    color:white;
}

li{
    transition: color 0.8s linear 0.2s;
}

button:focus {outline:0;}

.list-inline-item{
    padding-right: 30px;
    color:#eeeeee;
}

.list-inline-item a{
    color:#626262;
    font-weight: bold;
    font-family: ProductSansBold;
}

.sombras{
    -webkit-box-shadow: -4px 4px 11px 0px rgba(51,51,51,0.25);
    -moz-box-shadow: -4px 4px 11px 0px rgba(51,51,51,0.25);
    box-shadow: -4px 4px 11px 0px rgba(51,51,51,0.25);
    min-height: 50px;
    max-width:50px;
    position: relative;
    border-radius:50%;
    margin-bottom:-30px;
    color:#626262;
    text-align:center;
    font-size:30px;
    vertical-align:middle;
    background-color:#ffffff;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 5px;
}

.input-group-text{
    background-color: #ffffff !important;
    border-left: 0px;
}

.input-contact-user{
    padding-top: 2px;
    vertical-align: top;
    width: 450px !important;
    background-image: url(/user.png);
    background-repeat: no-repeat;
    background-position: 98%;
}

.input-contact-mail{
    padding-top: 2px;
    vertical-align: top;
    width: 450px !important;
    background-image: url(/email-black.png);
    background-repeat: no-repeat;
    background-position: 98%;
}

textarea.input-message {
    height: 40px;
    margin-top: 20px;
}
textarea.input-message:focus {
    height: 100px;
}
textarea.input-message {
    transition: height 0.8s linear 0.2s;

}

/*Estilos Base*/
.contenedor-img {
    width: 300px;
    height: 200px;
    float: left;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;
    background: #fff;
}

.mascara {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
}

.mascarados {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;

}

div:hover.mascara {
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    /*background: red;*/
}

div:hover.mascarados {
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    background: red;
}

.contenedor-img img {
    display: block;
    position: relative;

}
.contenedor-img h2 {
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 17px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.9);
    margin: 20px 0 0 0
}
.contenedor-img p {
    font-size: 12px;
    position: relative;
    color: #fff;
    padding: 10px 20px 10px;
    text-align: center
}
.contenedor-img a.link {
    display: inline-block;
    text-decoration: none;
    padding: 7px 14px;
    background: #222;
    color: #fff;
    text-transform: uppercase;
    box-shadow: 0 0 1px #000
}
.contenedor-img a.link:hover {
    box-shadow: 0 0 5px #000
}

/*Ejemplo 1*/
.ejemplo-1 img {
    transition: all 0.2s linear;
}
.ejemplo-1 .mascara {
    opacity: 0;
    background-color: rgba(46,157,120, 0.7);
    transition: all 0.3s ease-in-out;
}
.ejemplo-1 h2 {
    transform: translateX(-200px);/*Desplazamos a la izquierda*/
    opacity: 0;
    transition: all 0.7s ease-in-out;
}
.ejemplo-1 p {
    transform: translateX(200px);/*Desplazamos a la derecha*/
    opacity: 0;
    transition: all 0.4s linear;
}
.ejemplo-1 a.link{
    opacity: 0;
    transition: all 0.4s ease-in-out;
    transform: translateY(100px)/*Desplazamos para abajo*/
}
.ejemplo-1:hover img {
    transform: scale(1.1);/*Damos un ligero zoom a la imagen*/
}
.ejemplo-1:hover .mascara {
    opacity: 1;
}
.ejemplo-1:hover h2,
.ejemplo-1:hover p,
.ejemplo-1:hover a.link {
    opacity: 1;
    transform: translateX(0px);/*Regresamos a las posiciones originales*/
}
.ejemplo-1:hover p {
    transition-delay: 0.1s;
}
.ejemplo-1:hover a.link {
    transition-delay: 0.2s; /*Aplicamos un pequeño retardo para que se muestre al final*/
    transform: translateY(0px);
}

.ir-arriba {
    display: none;
    -webkit-box-shadow: -4px 4px 11px 0px rgba(51,51,51,0.25);
    -moz-box-shadow: -4px 4px 11px 0px rgba(51,51,51,0.25);
    box-shadow: -4px 4px 11px 0px rgba(51,51,51,0.25);
    padding:20px;
    background:#ffffff;
    font-size:20px;
    color:#626262;
    cursor:pointer;
    position: fixed;
    bottom:180px;
    right:20px;
    height: 55px;
    border-radius: 50%;
}

div#info{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgb(31,31,31,0.9);
    -webkit-transition: opacity 0.2s;
    opacity: 0;
}

div.foto-materials:hover div#info{
    opacity: 1;

}

p#headline{
    position: absolute;
    -webkit-transition: margin 0.2s;
    margin-left: -195px;
    margin-top: 20px;
    font-size: 2vw;
    color: white;
}

div.foto-materials:hover p#headline{
    margin-left: 240px;

}

p#info2{
    width: 100%;
    text-align: center;
    margin: auto;
    color: white;
    margin-top: 460px;
    -webkit-transition: margin 0.3s;
    font-size: 1.2vw;
    padding: 10px;
}

div.foto-materials:hover p#info2{
    margin-top: 10px;
}

.contacto {
    text-align: "center";
    color: "28B463";
}